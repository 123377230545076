<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left" style="margin-top: 100px">
      <div class="title-container">
        <h3 class="title">登录</h3>
      </div>
      <el-form-item prop="username">
        <span class="svg-container">
          <!--          <svg-icon icon-class="user" />-->
        </span>
        <el-input v-model="loginForm.username" name="username" type="text" tabindex="1" auto-complete="on" placeholder="请输入账户" />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <!--          <svg-icon icon-class="password" />-->
        </span>
        <el-input v-model="loginForm.password" :type="passwordType" name="password" auto-complete="on" tabindex="2" show-password placeholder="请输入密码" @keyup.enter.native="handleLogin" />
      </el-form-item>

      <el-button :loading="loading" type="primary" style="width: 100%; margin-bottom: 30px" @click.native.prevent="handleLogin">登录</el-button>
    </el-form>

       <div class="copyright1">Copyright © 2024 福州巨莱昂科技有限公司 版权所有 </div>
       <div class="copyright2">julaiang.com<a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2023003531-2号</a></div>
       <div class="copyright3">julaiang.cn<a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2023003531-1号</a></div>
       
  </div>
</template>

<script>
import { setToken } from "@/utils/auth";
import { encryptedData } from "../../utils/RSAUtils";

export default {
  name: "Login",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("管理员密码长度应大于6"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [{ required: true, message: "管理员账户不允许为空", trigger: "blur" }],
        password: [{ required: true, message: "管理员密码不允许为空", trigger: "blur" }],
      },
      passwordType: "password",
      loading: false,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    // window.addEventListener('hashchange', this.afterQRScan)
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    handleLogin() {
      this.$store
        .dispatch("Login", this.loginForm)
        .then(() => {
          this.topsAjax.get("/api/system/notificationsSettings/selectNtfSet").then((response) => {
            sessionStorage.setItem("notificationsSettings", JSON.stringify(response));
          });
          this.topsAjax.get("/api/system/deliverySettings/selectDelSet").then((response) => {
            sessionStorage.setItem("deliverySettings", JSON.stringify(response));
          });
          this.$router.push({ path: "/index" || "/" });
          const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
          this.$store.dispatch("initWebSocket", userInfo.userName);
        })
        .catch(() => {
          // this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  background-image: url("~@/assets/login_background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .copyright1 {
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 80px;
    letter-spacing: 0.6px;

    a {
      font-weight: bold;
      border-bottom: 1px solid #fff;
      font-family: "PingFangSC-Semibold", sans-serif;
    }
  }
  .copyright2 {
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 50px;
    letter-spacing: 0.6px;

    a {
      font-weight: bold;
      border-bottom: 1px solid #fff;
      font-family: "PingFangSC-Semibold", sans-serif;
    }
  }
  .copyright3 {
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 20px;
    letter-spacing: 0.6px;

    a {
      font-weight: bold;
      border-bottom: 1px solid #fff;
      font-family: "PingFangSC-Semibold", sans-serif;
    }
  }
}
</style>
